
import { number_to_currency } from '@/lib/helpers'

export default {
  data: -> {
    baskets: [],
    cheapest_basket: {
      items: [],
      total_in_cents: null
    }
  }

  created: ->
    @$store.dispatch('cart/fetch_comparisons_debug')
    .then (comparison) =>
      @baskets = comparison.baskets
      @cheapest_basket = comparison.cheapest_basket

  methods: {
    as_currency: (number) ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: number })
  }
}
